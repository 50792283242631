import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Restaurant Reservations App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/restaurant-reservations-mobile-app/"
    metaDescription="Looking to design a booking app for you own restaurant? Our mobile app mockup for table reservations is perfect for any business. Try now."
    description="
    h2:Introducing TableNow, a table booking app design template for mobile
    <br/>
    Tired of poor service with your design tools? Want a reason to give a generous tip during the <a:https://uizard.io/wireframing/>wireframing</a> and prototyping process? Perhaps you've got an appetite for rapid mockup creation? Uizard templates might just be the perfect solution. Design your own table booking app today with TableNow, a UI app template.
    <br/>
    h3:I'd like an easy-to-use design tool with beautiful premade UI design templates, please waiter.
    <br/>
    All the core UI considerations baked in, the ideal user journeys adequately seasoned, every screen delicately flavored with world class design aesthetic… TableNow is a grand opening you don't want to miss.
    <br/>
    h3:Try out our Michelin star-worthy table booking app template with Uizard Pro
    <br/>
    Making <a:https://uizard.io/mockups/>app UI mockups</a> has never been so easy. To create your own restaurant booking app design at rapid speed, simply sign up to Uizard Pro, select TableNow and get to work. You can even customize the app to suit your own branding or business before you hand-off your design to a developer.
    "
    pages={[
      "A stylish and refined home page with 'Find a restaurant' CTA",
      "Restaurant browsing screen mockup",
      "A table booking screen app to show how your users would convert in-app.",
      "Booking management and cancellation screen mockups",
    ]}
    projectCode="d9RWaZAW34UZdp7lmn7d"
    img1={data.image1.childImageSharp}
    img1alt="table booking app design template overview"
    img2={data.image2.childImageSharp}
    img2alt="table booking app design template reservation detail screen"
    img3={data.image3.childImageSharp}
    img3alt="table booking app design template reservation confirmation screen"
    img4={data.image4.childImageSharp}
    img4alt="table booking app design template calendar screen"
    img5={data.image5.childImageSharp}
    img5alt="table booking app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/table-booking-mobile-app/table-booking-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/table-booking-mobile-app/table-booking-app-detail.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/table-booking-mobile-app/table-booking-app-reservations.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/table-booking-mobile-app/table-booking-app-calendar.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/table-booking-mobile-app/table-booking-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
